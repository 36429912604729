export type ApiValidationError = {
  [key: string]: string[];
};

export function isApiError(error: unknown): error is ApiValidationError {
  return (
    typeof error === 'object' &&
    error !== null &&
    Object.keys(error).length > 0 &&
    Object.values(error).every((value) => Array.isArray(value))
  );
}
