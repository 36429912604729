import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { SuperAdminTabSelect } from './components/SuperAdminTabSelect';
import { useState } from 'react';
import { SuperAdminTab } from './components/SuperAdminTab';
import { UniversityManagement } from './components/UniversityManagement';
import { useAuthContext } from '../../contexts/AuthContext';

export type TabType =
  | 'licenses'
  | 'payments'
  | 'publishers'
  | 'publishing-models';

const SuperAdmin = () => {
  const { account } = useAuthContext();
  const [tab, setTab] = useState<TabType>('licenses');

  const handleTabSelect = (tab: TabType) => {
    setTab(tab);
  };
  return (
    <Card>
      <Grid container spacing={2} p={16}>
        <Grid item xs={9}>
          <Typography variant="h1" sx={{ fontSize: 20, marginBottom: 16 }}>
            Super Admin
          </Typography>
          <Typography variant="h2" sx={{ fontSize: 18 }}>
            General configuration
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            Create and manage licenses, payments, publishers and publishing
            models.
          </Typography>
        </Grid>
        <Divider />
        <Box sx={{ width: '100%' }}>
          <SuperAdminTabSelect tab={tab} onTabSelect={handleTabSelect} />
          <Card>
            <CardContent>
              <SuperAdminTab tab={tab} />
            </CardContent>
          </Card>
        </Box>
        {account !== 'admin' && (
          <>
            <Divider />
            <Box sx={{ width: '100%' }}>
              <Typography variant="h2" sx={{ fontSize: 18 }}>
                University configuration
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ marginBottom: 16 }}
              >
                Manage university contracts, features and more.
              </Typography>
              <UniversityManagement />
            </Box>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default SuperAdmin;
