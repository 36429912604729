import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { queryClient } from './react-query';

export const usePublishers = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: publishers } = useQuery({
    queryKey: ['publishers', account],
    queryFn: () => makeApiRequest('/publishers', 'GET'),
    enabled: !!account,
  });

  const { mutateAsync: createPublisher, isPending: isCreatingPublisher } =
    useMutation({
      mutationFn: ({ id }: { id: string }) =>
        makeApiRequest('/publishers', 'POST', { name: id }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['publishers', account] });
      },
    });

  return {
    publishers,
    createPublisher,
    isCreatingPublisher,
  };
};
