import { BrowserUpdated, OpenInNew } from '@mui/icons-material';
import Statistics from '@mui/icons-material/AutoGraph';
import BrandingIcon from '@mui/icons-material/Brush';
import Agreements from '@mui/icons-material/HistoryEdu';
import Search from '@mui/icons-material/ManageSearch';
import UserManagement from '@mui/icons-material/PeopleOutline';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGetAccessToken } from '../../hooks/useGetAccessToken';
import { SidebarItemsType } from '../../types/sidebar';
import { useEffect, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';
const searchUrl =
  process.env.REACT_APP_PUBLIC_SEARCH_URL || 'https://search.scifree.se';

const DashboardItems = () => {
  const { account, loggedInAccount } = useAuthContext();
  const { getToken } = useGetAccessToken();

  const [token, setToken] = useState<string>('');
  useEffect(() => {
    getToken().then((token) => {
      setToken(token || '');
    });
  }, [getToken]);

  const pagesSection = [
    {
      children: [
        {
          href: '/agreements',
          title: 'Agreements',
          icon: Agreements,
        },
        {
          href: '/internal-search',
          title: 'Internal Search',
          icon: Search,
        },
        {
          href: '/statistics',
          title: 'Statistics',
          icon: Statistics,
        },
        {
          href: '/user-management',
          title: 'Admin Management',
          icon: UserManagement,
        },
        {
          href: '/branding',
          title: 'Branding',
          icon: BrandingIcon,
        },
        {
          href: '/notifications',
          title: 'Notifications',
          icon: MailOutlineIcon,
        },
        {
          href: `${searchUrl}/${account}`,
          title: 'Public Search',
          icon: OpenInNew,
        },
        {
          href: `${baseUrl}/export?token=${token}&context=${account}`,
          title: 'Export Journals',
          icon: BrowserUpdated,
        },
        loggedInAccount === 'admin'
          ? {
              href: '/superadmin',
              title: 'Super Admin',
              icon: WarningAmberIcon,
            }
          : null,
      ].filter(Boolean),
    },
  ] as SidebarItemsType[];

  return pagesSection;
};

export default DashboardItems;
