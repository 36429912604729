import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useGetAccessToken } from '../useGetAccessToken';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.jstscifree.com';

export function useApi() {
  const { account } = useAuthContext();
  const { getToken } = useGetAccessToken();

  const makeApiRequest = useCallback(
    async (
      url: string,
      method: AxiosRequestConfig['method'],
      data: any = null
    ) => {
      const token = await getToken();

      const config: AxiosRequestConfig = {
        method,
        url: `${BASE_URL}${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-SciFree-Context': account,
        },
        data,
      };

      try {
        const response: AxiosResponse = await axios(config);
        return response.data;
      } catch (error) {
        // If it's an axios error, we can access the response data
        if (axios.isAxiosError(error) && error.response) {
          throw error.response.data; // Throw the actual error message from the API
        }
        // If it's not an axios error, rethrow the original error
        throw error;
      }
    },
    [getToken, account]
  );

  return { makeApiRequest };
}
