import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { queryClient } from './react-query';

export const usePayments = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: payments } = useQuery({
    queryKey: ['payments', account],
    queryFn: () => makeApiRequest('/payments', 'GET'),
    enabled: !!account,
  });

  const { mutateAsync: createPayment, isPending: isCreatingPayment } =
    useMutation({
      mutationFn: ({
        id,
        description,
        icon,
      }: {
        id: string;
        description?: string;
        icon?: string;
      }) =>
        makeApiRequest('/payments', 'POST', { name: id, description, icon }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['payments', account] });
      },
    });

  return {
    payments,
    createPayment,
    isCreatingPayment,
  };
};
