import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { queryClient } from './react-query';

export const usePublishingModels = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: publishingModels } = useQuery({
    queryKey: ['publishingModels', account],
    queryFn: () => makeApiRequest('/publishing_models', 'GET'),
    enabled: !!account,
  });

  const {
    mutateAsync: createPublishingModel,
    isPending: isCreatingPublishingModel,
  } = useMutation({
    mutationFn: ({
      id,
      description,
      icon,
    }: {
      id: string;
      description?: string;
      icon?: string;
    }) =>
      makeApiRequest('/publishing_models', 'POST', {
        name: id,
        description,
        icon,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['publishingModels', account],
      });
    },
  });

  return {
    publishingModels,
    createPublishingModel,
    isCreatingPublishingModel,
  };
};
