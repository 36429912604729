import { useQuery, useMutation } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { NotificationStatus } from '../../types/Notifications.types';
import { queryClient } from './react-query';

export const useNotifications = (status?: NotificationStatus) => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: notifications, isLoading: isNotificationsLoading } = useQuery({
    queryKey: ['notifications', account, status ?? 'all'],
    queryFn: () =>
      makeApiRequest(
        status ? `/notifications/${status}` : '/notifications',
        'GET'
      ),

    enabled: !!account,
    staleTime: 1000 * 60 * 0.5,
  });

  const { mutateAsync: updateNotification } = useMutation({
    mutationFn: ({
      notificationId,
      newStatus,
    }: {
      notificationId: string;
      newStatus: 'read' | 'unread';
    }) =>
      makeApiRequest(`/notifications/${notificationId}`, 'PUT', {
        status: newStatus,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', account] });
    },
  });

  return {
    notifications,
    isNotificationsLoading,
    updateNotification,
  };
};
