import { PageComponentType } from '../../../types/pageComponent';
import AutoGraph from '../../../assets/icons/auto_graph.svg';
import HistoryEdu from '../../../assets/icons/history_edu.svg';
import ManageSearch from '../../../assets/icons/manage_search.svg';
import PeopleOutline from '../../../assets/icons/people_outline.svg';
import OpenInNew from '../../../assets/icons/open_in_new.svg';
import Brush from '../../../assets/icons/brush_white.svg';
import MailOutline from '../../../assets/icons/mail_outline.svg';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const PageComponent = () => {
  const { account } = useAuthContext();
  const { t } = useTranslation();
  const searchUrl =
    process.env.REACT_APP_PUBLIC_SEARCH_URL || 'https://search.scifree.se';

  const pageComponents = [
    {
      title: 'home.agreementsTitle',
      icon: HistoryEdu,
      subtext: 'Create or edit your agreements for your public search',
      url: 'agreements',
    },
    {
      title: 'home.tableTitle',
      icon: ManageSearch,
      subtext:
        'Search for journals within your agreements without affecting your statistics',
      url: 'internal-search',
    },
    {
      title: 'home.statisticsTitle',
      icon: AutoGraph,
      subtext:
        'Find statistics relating to how your public search is being used by your researchers',
      url: 'statistics',
    },
    {
      title: 'home.userManagementTitle',
      icon: PeopleOutline,
      subtext: 'Manage your JST admins including adding or removing accounts',
      url: 'user-management',
    },
    {
      title: 'home.brandingTitle',
      icon: Brush,
      subtext:
        'Make customisations for your public search page including texts, colours and icons',
      url: 'branding',
    },
    {
      title: 'home.notificationsTitle',
      icon: MailOutline,
      subtext: t('home.notificationsSubtext'),
      url: 'notifications',
    },
    {
      title: 'home.publicSearchTitle',
      icon: OpenInNew,
      subtext: 'Direct link to your public search page',
      url: `${searchUrl}/${account}`,
    },
  ] as PageComponentType[];

  return pageComponents;
};

export default PageComponent;
