import styled from '@emotion/styled';
import { Box, Button, Paper } from '@mui/material';
import { useState } from 'react';
import { TabType } from '../Notifications';
import { useTranslation } from 'react-i18next';

const StyledTabBtn = styled(Button)`
  border-radius: 0;

  &:focus {
    background: rgba(0, 0, 0, 0.04);
  }

  &:hover {
  }
`;

const activeTabStyles = {
  borderBottom: '3px solid #177d8e',
  background: 'rgba(0, 0, 0, 0.04)',
};

type TabSelectProps = {
  onTabSelect: (tab: TabType) => void;
};

export const NotificationsTabSelect = ({ onTabSelect }: TabSelectProps) => {
  const [value, setValue] = useState('unread');
  const { t } = useTranslation();

  const handleTabClick = (tab: TabType) => {
    onTabSelect(tab);
    setValue(tab);
  };
  return (
    <Box
      component={Paper}
      sx={{
        marginTop: 12,
      }}
    >
      <StyledTabBtn
        onClick={() => handleTabClick('all')}
        sx={value === 'all' ? activeTabStyles : {}}
      >
        {t('notifications.all')}
      </StyledTabBtn>
      <StyledTabBtn
        onClick={() => handleTabClick('unread')}
        sx={value === 'unread' ? activeTabStyles : {}}
      >
        {t('notifications.unread')}
      </StyledTabBtn>
      <StyledTabBtn
        onClick={() => handleTabClick('read')}
        sx={value === 'read' ? activeTabStyles : {}}
      >
        {t('notifications.read')}
      </StyledTabBtn>
    </Box>
  );
};
