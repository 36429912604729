import React, { useMemo } from 'react';
import { Journals } from '../../../../types/Journals.types';

import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';

import styled from '@emotion/styled';
import { PlaylistAdd } from '@mui/icons-material';
import { spacing } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { JournalModal } from './JournalModal';

const StyledGrid = styled(Grid)(spacing);

const StyledErrorBadgeContainer = styled(Badge)`
  display: flex;
  align-items: center;
`;

//Create a typography with styling
const StyledTypography = styled(Typography)(spacing);

const ErrorBadge = ({
  title,
  style,
  children,
}: {
  title?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => (
  <StyledErrorBadgeContainer style={style}>
    <Badge
      badgeContent={'!'}
      color="warning"
      style={{ paddingLeft: '16px' }}
      title={title}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    />
    {children && <div>{children}</div>}
  </StyledErrorBadgeContainer>
);

function JournalsTable(props: {
  agreementJournals: Journals[];
  preview: boolean;
}) {
  const [journals, setJournals] = React.useState<Journals[]>([]);
  const { impersonationLock } = useAuthContext();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [journal, setJournal] = React.useState<Journals>();
  const [onlyShowBroken, setOnlyShowBroken] = React.useState<boolean>(false);
  const { agreementId } = useParams<{ agreementId: string }>();
  React.useEffect(() => {
    if (props.agreementJournals) {
      setJournals(props.agreementJournals);
    }
  }, [agreementId, props]);

  React.useEffect(() => {
    if (onlyShowBroken) {
      setJournals(
        props.agreementJournals.filter((journal) => isMissingData(journal))
      );
    } else {
      setJournals(props.agreementJournals);
    }
  }, [onlyShowBroken, props]);

  const isMissingColumn = (journal: Journals, key: keyof Journals): boolean => {
    return Array.isArray(journal[key]) ? !journal[key]?.length : !journal[key];
  };

  const isMissingData = (journal: Journals) => {
    return (
      !journal.name ||
      !journal.publishing_model ||
      journal.licenses?.length === 0 ||
      !journal.payment
    );
  };

  const someMissingPublishingModel = journals.some((journal) =>
    isMissingColumn(journal, 'publishing_model')
  );
  const someMissingPayment = journals.some((journal) =>
    isMissingColumn(journal, 'payment')
  );
  const someMissingLicenses = journals.some((journal) =>
    isMissingColumn(journal, 'licenses')
  );
  const someMissingName = journals.some((journal) =>
    isMissingColumn(journal, 'name')
  );
  const missingLabel = useMemo(() => {
    const missing = [];
    if (someMissingPublishingModel) {
      missing.push('publishing model');
    }
    if (someMissingPayment) {
      missing.push('payment');
    }
    if (someMissingLicenses) {
      missing.push('licenses');
    }
    if (someMissingName) {
      missing.push('name');
    }
    if (missing.length === 0) {
      return '';
    }
    let joined =
      missing.length > 2 ? missing.join(', ') : missing.join(' and ');
    return (
      <>
        Some journals are missing the following mandatory data:{' '}
        <strong>{joined}</strong>
      </>
    );
  }, [
    someMissingPublishingModel,
    someMissingPayment,
    someMissingLicenses,
    someMissingName,
  ]);

  if (!journals && !(window.location.pathname === '/agreements/add')) {
    return <Loader />;
  }

  const handleAddNew = () => {
    setJournal(undefined);
    setOpen(true);
  };

  const handleOpen = (journal: Journals) => {
    setJournal(journal);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledGrid container spacing={6}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Grid container>
            <Grid item xs={10}>
              <StyledTypography
                variant="h3"
                sx={{ fontSize: '18px' }}
                gutterBottom
              >
                {props.preview
                  ? 'Journal preview'
                  : t('agreements.uploadJournal')}
                <FormControlLabel
                  sx={{ marginLeft: '12px' }}
                  control={
                    <Checkbox
                      value={onlyShowBroken}
                      onChange={() => setOnlyShowBroken(!onlyShowBroken)}
                    />
                  }
                  label="Only show entries with missing mandatory data"
                />
              </StyledTypography>
              {missingLabel && (
                <ErrorBadge style={{ marginTop: '18px', marginBottom: '14px' }}>
                  <Typography variant="body2" color="error">
                    {missingLabel}
                  </Typography>
                </ErrorBadge>
              )}
            </Grid>
            {!props.preview && (
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  endIcon={<PlaylistAdd />}
                  onClick={handleAddNew}
                  disabled={impersonationLock}
                >
                  Add Journal
                </Button>
              </Grid>
            )}
          </Grid>
          <TableVirtuoso
            style={{ height: '640px' }}
            components={{
              Table: (props) => (
                <Table
                  {...props}
                  sx={{ minWidth: 650 }}
                  aria-label="journal preview table"
                />
              ),
              TableBody: React.forwardRef((props, ref) => (
                <TableBody {...props} ref={ref} />
              )),
              TableHead: TableHead,
              TableRow: React.forwardRef<
                HTMLTableRowElement,
                React.ComponentPropsWithRef<typeof TableRow> & {
                  item: Journals;
                }
              >(({ item: journal, style, ...rest }, ref) => (
                <TableRow
                  {...rest}
                  component="tr"
                  ref={ref}
                  key={`${journal.issn_e}`}
                  style={style} // Important for virtuoso
                  sx={{
                    backgroundColor: isMissingData(journal)
                      ? '#fff4e5'
                      : 'white',
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#eee',
                    },
                  }}
                  onClick={() => handleOpen(journal)}
                />
              )),
            }}
            fixedHeaderContent={() => (
              <TableRow sx={{ backgroundColor: '#fff' }}>
                <TableCell sx={{ width: '25%' }}>
                  {t('agreements.journal')} *
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  {t('agreements.imprint')}
                </TableCell>
                <TableCell sx={{ width: '10%' }}>Payment Option</TableCell>
                <TableCell sx={{ width: '10%' }}>License Options</TableCell>
                <TableCell sx={{ width: '10%' }}>Publishing model *</TableCell>
                <TableCell sx={{ width: '7%' }}>
                  {t('agreements.issne')}
                </TableCell>
                <TableCell sx={{ width: '7%' }}>ISSN-P</TableCell>
                <TableCell sx={{ width: '21%' }}>Subject Areas</TableCell>
              </TableRow>
            )}
            data={journals}
            itemContent={(_index, journal) => (
              <>
                <TableCell>
                  <a
                    href={journal.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#233043' }}
                  >
                    {isMissingColumn(journal, 'name') ? (
                      <ErrorBadge title="Journal name is missing." />
                    ) : (
                      journal.name
                    )}
                  </a>
                </TableCell>
                <TableCell>{journal.imprint}</TableCell>
                <TableCell>
                  {isMissingColumn(journal, 'payment') ? (
                    <ErrorBadge title="Journal payment is missing." />
                  ) : (
                    journal.payment
                  )}
                </TableCell>
                <TableCell>
                  {isMissingColumn(journal, 'licenses') ? (
                    <ErrorBadge title="Journal licenses are missing." />
                  ) : journal.licenses ? (
                    journal.licenses.join(', ')
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>
                  {isMissingColumn(journal, 'publishing_model') ? (
                    <ErrorBadge title="Journal publishing model is missing." />
                  ) : (
                    journal.publishing_model
                  )}
                </TableCell>
                <TableCell>{journal.issn_e}</TableCell>
                <TableCell>{journal.issn_p}</TableCell>
                <TableCell>{journal.subjects}</TableCell>
              </>
            )}
          />
          <JournalModal
            journal={journal}
            open={open}
            handleClose={handleClose}
            editMode={!!journal}
          />
        </Grid>
      </StyledGrid>
    </div>
  );
}

export default JournalsTable;
