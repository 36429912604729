import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Typography,
  Menu,
  FormGroup,
  Paper,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Grid,
  Box,
  Card,
  Popover,
} from '@mui/material';
import { ExpandMore, UnfoldMore } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import { Journals } from '../../../../types/Journals.types';

import { useSearch } from '../../../../hooks/api/useSearch';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { usePersistentSorting } from '../../../../hooks/usePersistentSorting';
import { Link } from 'react-router-dom';

const StyledButton = styled(Button)`
  && {
    padding: 8px;
    text-transform: none;
    font-weight: 500;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }

    &:focus {
      outline: 2px solid #177d8e;
    }
  }
`;

const StyledFilterButton = styled(Button)`
  &:focus {
    outline: 2px solid #0b313f;
  }
`;

const arrowStylingDefault = {
  transition: 'transform 150ms ease',
};

const arrowStyling = {
  transform: 'rotate(-180deg)',
  transition: 'transform 150ms ease',
};
type Filters = {
  licenses: string[];
  publishingModels: string[];
  payments: string[];
  imprints: string[];
};

export default function InternalSearchTableView() {
  const [journals, setJournals] = React.useState<Journals[]>();
  const [search, setSearch] = React.useState<string>('');
  const [totalSearch, setTotalSearch] = React.useState<number>(0);
  const [selectedLicenses, setSelectedLicenses] = React.useState<string[]>([]);
  const [selectedPublishingModels, setSelectedPublishingModels] =
    React.useState<string[]>([]);
  const { order, orderBy, setOrder, setOrderBy } =
    usePersistentSorting<Journals>('agreement_id', 'journals');

  const [selectedPayments, setSelectedPayments] = React.useState<string[]>([]);
  const [selectedImprints, setSelectedImprints] = React.useState<string[]>([]);

  const { searchJournals } = useSearch();

  const [filters, setFilters] = React.useState<Filters>({
    licenses: [],
    publishingModels: [],
    payments: [],
    imprints: [],
  });

  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const { account } = useAuthContext();

  const { t } = useTranslation();

  const [anchorElLicense, setAnchorElLisence] =
    React.useState<null | HTMLElement>(null);
  const [anchorElPayments, setAnchorElPayments] =
    React.useState<null | HTMLElement>(null);
  const [anchorElimprints, setAnchorElimprints] =
    React.useState<null | HTMLElement>(null);

  const openimprints = Boolean(anchorElimprints);
  const openLicense = Boolean(anchorElLicense);
  const openPayments = Boolean(anchorElPayments);

  const handleClickLicense = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLisence(event.currentTarget);
  };

  const handleCloseLicense = () => {
    setAnchorElLisence(null);
  };

  const handleClickimprints = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElimprints(event.currentTarget);
  };

  const handleCloseimprints = () => {
    setAnchorElimprints(null);
  };

  const handleClickPayments = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPayments(event.currentTarget);
  };

  const handleClosePayments = () => {
    setAnchorElPayments(null);
  };

  const [anchorElModel, setAnchorElModel] = React.useState<null | HTMLElement>(
    null
  );
  const openModel = Boolean(anchorElModel);

  const handleClickModel = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElModel(event.currentTarget);
  };

  const handleCloseModel = () => {
    setAnchorElModel(null);
  };

  const handleSearch = () => {
    setSearch(searchTerm);
  };

  React.useEffect(() => {
    const getJournals = async () => {
      const { data, licenses, imprints, payments, publishingModels } =
        await searchJournals(
          search,
          selectedLicenses,
          selectedPublishingModels,
          selectedPayments,
          selectedImprints
        );
      const journals: Journals[] = data?.data;
      setJournals(journals);
      setTotalSearch(data?.total_count || 0);
      setFilters({
        licenses,
        imprints,
        payments,
        publishingModels,
      });
    };
    getJournals();
  }, [
    search,
    account,
    selectedLicenses,
    selectedPublishingModels,
    selectedPayments,
    selectedImprints,
    searchJournals,
  ]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Journals
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedJournals = React.useMemo(() => {
    if (!journals) return [];

    return journals.sort((a, b) => {
      const valueA = a[orderBy];
      const valueB = b[orderBy];
      if (valueA! > valueB!) return order === 'asc' ? 1 : -1;
      if (valueA! < valueB!) return order === 'asc' ? -1 : 1;
      return 0;
    });
  }, [journals, order, orderBy]);

  const getArrowIcon = (key: keyof Journals) => {
    if (orderBy === key) {
      return order === 'asc' ? (
        <ExpandMore fontSize="small" style={arrowStyling} />
      ) : (
        <ExpandMore fontSize="small" style={arrowStylingDefault} />
      );
    }
    return <UnfoldMore fontSize="small" style={arrowStylingDefault} />;
  };

  const noResultBanner = () => {
    return (
      <Card
        sx={{
          borderRadius: 0,
          textAlign: 'center',
          height: 45,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ my: 12, color: '#666', fontWeight: 500 }}
        >
          {t('publicSearch.no_results')}
        </Typography>
      </Card>
    );
  };

  const handleKeyPress = (keyCode: string) => {
    if (keyCode === 'Enter') {
      handleSearch();
    }
  };

  const handleChange = (
    item: string,
    array: string[],
    setter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    if (array.indexOf(item) === -1) {
      setter([...array, item]);
    } else {
      array.splice(array.indexOf(item), 1);
      setter([...array]);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        flexDirection="column"
        p={12}
        component={Paper}
        mb={12}
      >
        <Grid item>
          <Typography variant="h1" sx={{ fontSize: 20 }}>
            {t('publicSearch.internalSearch')}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            {t('publicSearch.searchDescription')}
          </Typography>
        </Grid>
        <Grid item>
          <div
            style={{
              marginTop: '12px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
              }}
            >
              <TextField
                label="Search"
                onKeyDown={(e) => handleKeyPress(e.code)}
                defaultValue={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  width: { xs: '200px', sm: '400px', lg: '500px' },
                  alignSelf: 'center',
                }}
                InputLabelProps={{
                  style: {
                    color: '#575959',
                  },
                }}
              />
              <StyledFilterButton
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
                sx={{ ml: 12, my: 'auto', height: 36 }}
              >
                Search
              </StyledFilterButton>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ ml: 24, alignSelf: 'center' }}
              >
                {totalSearch} results
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
        >
          <TableHead>
            <TableRow sx={{ borderBottom: '2px solid #E0E0E0' }}>
              <TableCell>
                <StyledButton
                  onClick={(event) => handleRequestSort(event, 'name')}
                >
                  {t('agreements.journal')}
                  {getArrowIcon('name')}
                </StyledButton>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {t('agreements.imprint')}
                  </Typography>
                  {filters.imprints.length > 0 && (
                    <StyledFilterButton
                      aria-label="Filter button"
                      color="secondary"
                      id="basic-button"
                      aria-controls={openimprints ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openimprints ? 'true' : undefined}
                      onClick={handleClickimprints}
                      style={{ minWidth: '30px' }}
                    >
                      <TuneIcon aria-label="This is a filter icon, press Enter to open" />
                    </StyledFilterButton>
                  )}
                </div>

                <Popover
                  open={openimprints}
                  anchorEl={anchorElimprints}
                  onClose={handleCloseimprints}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormGroup>
                    {Array.from(filters.imprints).map((imprint) => (
                      <FormControlLabel
                        key={imprint}
                        control={
                          <Checkbox
                            checked={selectedImprints.includes(imprint)}
                            sx={{
                              '&:focus': {
                                borderColor: 'red', // Change border color to red on focus
                              },
                            }}
                            onChange={() =>
                              handleChange(
                                imprint,
                                selectedImprints,
                                setSelectedImprints
                              )
                            }
                          />
                        }
                        label={imprint}
                      />
                    ))}
                  </FormGroup>
                </Popover>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {t('agreements.createFlow.payment')}
                  </Typography>
                  {filters.payments.length > 0 && (
                    <StyledFilterButton
                      aria-label="Filter button"
                      color="secondary"
                      id="basic-button"
                      aria-controls={openPayments ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openPayments ? 'true' : undefined}
                      onClick={handleClickPayments}
                      style={{ minWidth: '30px' }}
                    >
                      <TuneIcon aria-label="This is a filter icon, press Enter to open" />
                    </StyledFilterButton>
                  )}
                </div>
                <Popover
                  open={openPayments}
                  anchorEl={anchorElPayments}
                  onClose={handleClosePayments}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormGroup>
                    {Array.from(filters.payments).map((payment) => (
                      <FormControlLabel
                        key={payment}
                        control={
                          <Checkbox
                            onChange={() =>
                              handleChange(
                                payment,
                                selectedPayments,
                                setSelectedPayments
                              )
                            }
                          />
                        }
                        label={payment}
                        checked={selectedPayments.indexOf(payment) !== -1}
                      />
                    ))}
                  </FormGroup>
                </Popover>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {t('agreements.licenseOption')}
                  </Typography>

                  {filters.licenses.length > 0 && (
                    <StyledFilterButton
                      aria-label="Filter button"
                      color="secondary"
                      id="basic-button"
                      aria-controls={openLicense ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openLicense ? 'true' : undefined}
                      onClick={handleClickLicense}
                      style={{ minWidth: '30px' }}
                    >
                      <TuneIcon aria-label="This is a filter icon, press Enter to open" />
                    </StyledFilterButton>
                  )}

                  <Popover
                    open={openLicense}
                    anchorEl={anchorElLicense}
                    onClose={handleCloseLicense}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <FormGroup>
                      {filters.licenses.map((license) => (
                        <FormControlLabel
                          key={license}
                          control={
                            <Checkbox
                              checked={selectedLicenses.indexOf(license) !== -1}
                              onChange={() =>
                                handleChange(
                                  license,
                                  selectedLicenses,
                                  setSelectedLicenses
                                )
                              }
                            />
                          }
                          label={license}
                        />
                      ))}
                    </FormGroup>
                  </Popover>
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {t('agreements.publishingModel')}
                  </Typography>
                  {filters.publishingModels.length > 0 && (
                    <StyledFilterButton
                      aria-label="Filter button"
                      color="secondary"
                      id="basic-button"
                      aria-controls={openModel ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openModel ? 'true' : undefined}
                      onClick={handleClickModel}
                      style={{ minWidth: '30px' }}
                    >
                      <TuneIcon
                        sx={{ ml: '4px' }}
                        aria-label="This is a filter icon, press Enter to open"
                      />
                    </StyledFilterButton>
                  )}
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElModel}
                  open={openModel}
                  onClose={handleCloseModel}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup>
                    {filters.publishingModels.map((model) => (
                      <FormControlLabel
                        key={model}
                        control={
                          <Checkbox
                            checked={
                              selectedPublishingModels.indexOf(model) !== -1
                            }
                            onChange={() =>
                              handleChange(
                                model,
                                selectedPublishingModels,
                                setSelectedPublishingModels
                              )
                            }
                          />
                        }
                        label={model}
                      />
                    ))}
                  </FormGroup>
                </Menu>
              </TableCell>
              <TableCell>
                <StyledButton
                  onClick={(event) => handleRequestSort(event, 'issn_e')}
                >
                  {t('agreements.issne')}
                  {getArrowIcon('issn_e')}
                </StyledButton>
              </TableCell>
              <TableCell>
                <StyledButton
                  onClick={(event) => handleRequestSort(event, 'issn_p')}
                >
                  {t('agreements.issnp')}
                  {getArrowIcon('issn_p')}
                </StyledButton>
              </TableCell>
              <TableCell>
                <StyledButton
                  onClick={(event) => handleRequestSort(event, 'subjects')}
                >
                  {t('agreements.subject')}
                  {getArrowIcon('subjects')}
                </StyledButton>
              </TableCell>
              <TableCell>
                <StyledButton
                  onClick={(event) =>
                    handleRequestSort(event, 'agreement_name')
                  }
                >
                  {t('agreements.agreement')}
                  {getArrowIcon('agreement_name')}
                </StyledButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedJournals?.map((journal) => (
              <TableRow key={journal.url + journal.name}>
                <TableCell>
                  <a
                    href={journal.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#233043' }}
                  >
                    {journal.name}
                  </a>
                </TableCell>
                <TableCell>{journal.imprint}</TableCell>
                <TableCell>{journal.payment}</TableCell>
                <TableCell>{journal.licenses?.join(', ')}</TableCell>
                <TableCell>{journal.publishing_model}</TableCell>
                <TableCell>{journal.issn_e}</TableCell>
                <TableCell>{journal.issn_p}</TableCell>
                <TableCell>{journal.subjects}</TableCell>
                <TableCell>
                  <Link
                    to={`/agreements/${journal.agreement_id}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#233043' }}
                  >
                    {journal.agreement_name}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!sortedJournals && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '400px',
            }}
          >
            <Box
              sx={{
                alignSelf: 'center',
              }}
            >
              <SearchIcon sx={{ fontSize: 100, color: '#404040' }} />
            </Box>
            <Typography
              sx={{
                alignSelf: 'center',
                fontSize: { xs: '18px', md: '20px' },
                width: { xs: '300px', md: '400px' },
                textAlign: 'center',
              }}
            >
              {t('publicSearch.tableDescription')}
            </Typography>
          </Box>
        )}
      </TableContainer>
      {sortedJournals.length > 0 ? '' : noResultBanner()}
    </>
  );
}
