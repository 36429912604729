import { Card, CardContent, Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import ErrorBanner from '../../../../components/errorhandling/ErrorBanner';
import { useAgreements } from '../../../../hooks/api/useAgreements';
import { useBranding } from '../../../../hooks/api/useBranding';
import { Journals } from '../../../../types/Journals.types';
import {
  ErrorObject,
  errorSeverity,
} from '../../../../types/errorhandling.types';
import { AgreementsFormComponent } from '../../Agreements.types';
import {
  StyledFormDivider,
  StyledFormGrid,
  StyledFormTypography,
} from '../../presetStyles/formPresets';
import { JournalsContainer } from '../journals/JournalContainer';
import { AgreementFormFields, FormDataType } from './AgreementForm.form';
import { DeleteAgreementModal } from './DeleteAgreementModal';

export const AgreementForm: AgreementsFormComponent = ({ agreement }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [journals, setJournals] = useState<Journals[]>([]);
  const [previewJournals, setPreviewJournals] = useState<Journals[]>([]);
  const [error, setError] = useState<string>();
  const [errors, setErrors] = useState<ErrorObject>();
  const [publishers, setPublishers] = useState<string[]>([]);
  const [paymentOptions, setPaymentOptions] = useState<string[]>([]);
  const [selectableLicenses, setSelectableLicenses] = useState<string[]>([]);
  const [paymentIcons, setPaymentIcons] = useState<any>();
  const [currentAgreement, setCurrentAgreement] = useState(agreement);
  const { agreementId } = useParams<{ agreementId: string }>();
  const {
    addAgreement,
    editAgreement,
    fetchPayments,
    fetchPublishers,
    fetchLicenses,
  } = useAgreements();
  const { branding } = useBranding();
  const payments = branding?.payments;
  useEffect(() => {
    if (payments) {
      setPaymentIcons(payments);
    }
  }, [payments]);

  const onPreviewJournals = useCallback((journals: Journals[]) => {
    setPreviewJournals(journals);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const selectablePublishers = await fetchPublishers();
      const selectablePaymentOptions = await fetchPayments();
      const selectableLicenses = await fetchLicenses();
      setPublishers(selectablePublishers);
      setPaymentOptions(selectablePaymentOptions);
      setSelectableLicenses(selectableLicenses);
    };

    fetchData().catch(console.error);

    if (!agreementId || !agreement) return;

    setJournals(agreement.journals ?? []);
  }, [
    agreementId,
    agreement,
    fetchPublishers,
    fetchPayments,
    fetchLicenses,
    branding,
    payments,
  ]);

  const isAdding = !agreementId;

  const brokenJournalCount = useMemo(() => {
    const brokenJournals = journals?.filter((journal: Journals) => {
      return (
        !journal.name ||
        !journal.publishing_model ||
        journal.licenses?.length === 0 ||
        !journal.payment
      );
    });
    return brokenJournals?.length ?? 0;
  }, [journals]);

  const onChangeHandler = useCallback((responseBody: FormDataType) => {
    setErrors(undefined);
    setError(undefined);
  }, []);

  const onSubmitHandler = useCallback(
    async (responseBody: FormDataType) => {
      if (isAdding) {
        setLoading(true);
      }
      setErrors(undefined);
      setError(undefined);
      const requestBody: FormData = new FormData();
      requestBody.append('name', responseBody.name);
      requestBody.append('publisher', responseBody.publisher);
      requestBody.append('start_date', responseBody.start_date);
      requestBody.append('end_date', responseBody.end_date);
      requestBody.append('payment_url', responseBody.payment_url);
      requestBody.append('file', responseBody.file);
      requestBody.append('payment', responseBody.payment);
      requestBody.append('description', responseBody.description);
      requestBody.append('notes', responseBody.notes);
      requestBody.append('extra_info', responseBody.extra_info);
      requestBody.append(
        'disabled',
        responseBody.disabled != null ? String(responseBody.disabled) : 'false'
      );
      responseBody.licenses.forEach((license) => {
        requestBody.append('licenses[]', license);
      });
      if (isAdding) {
        try {
          const response = await addAgreement(requestBody);
          navigate(`/agreements/${response._id}`);
        } catch (e: any) {
          setLoading(false);
          const errorExists = !!e.response.data;
          if (errorExists) {
            setErrors(e.response.data);
          } else {
            setError(
              'Something went wrong when creating the agreement. Please try again.'
            );
          }
        }
      } else if (!isAdding && agreementId) {
        try {
          const response = await editAgreement(requestBody, agreementId);
          setCurrentAgreement(response);
          setJournals(response.journals);
          navigate(`/agreements/${agreementId}`);
        } catch (e: any) {
          const errorExists = !!e.response.data;
          if (errorExists) {
            setErrors(e.response.data);
          } else {
            setError(
              'Something went wrong when editing the agreement. Please try again.'
            );
          }
        }
      }
    },
    [addAgreement, editAgreement, isAdding, agreementId, navigate]
  );

  if (
    (!isAdding && !agreement) ||
    loading ||
    !publishers ||
    !paymentOptions ||
    !selectableLicenses
  ) {
    return <Loader />;
  }

  return (
    <>
      {brokenJournalCount > 0 && (
        <ErrorBanner
          errorMessage="One or more of this agreements journal entries are missing vital data."
          severity={errorSeverity.Warning}
          layer={1}
        />
      )}
      {error && (
        <ErrorBanner
          errorMessage={error}
          severity={errorSeverity.Error}
          layer={brokenJournalCount > 0 ? 2 : 1}
        />
      )}
      {errors && (
        <ErrorBanner
          errors={errors}
          severity={errorSeverity.Error}
          layer={brokenJournalCount > 0 ? (error ? 3 : 2) : error ? 2 : 1}
        />
      )}
      <Card>
        <CardContent style={{ padding: 24 }}>
          <Grid container>
            <Grid item xs={10}>
              <StyledFormTypography variant="h1" sx={{ fontSize: '24px' }}>
                {isAdding
                  ? t('agreements.createAgreement')
                  : t('agreements.editAgreement')}
              </StyledFormTypography>
              <StyledFormTypography
                variant="body1"
                sx={{ color: 'grey', marginBottom: '1rem' }}
              >
                {t('agreements.asteriskRequired')}
              </StyledFormTypography>
            </Grid>
            <Grid item xs={2} sx={{ pt: 12 }}>
              {isAdding ? (
                ''
              ) : (
                <DeleteAgreementModal
                  id={agreementId!}
                  journalMode={false}
                  journalId={undefined}
                />
              )}
            </Grid>
          </Grid>
          <StyledFormGrid container>
            <Grid item sx={{ width: '100%', maxWidth: 900 }}>
              <AgreementFormFields
                isAdding={isAdding}
                agreementId={agreementId}
                agreement={currentAgreement}
                loading={loading}
                onPreviewJournals={onPreviewJournals}
                onSubmit={onSubmitHandler}
                onChange={onChangeHandler}
                publishers={publishers}
                paymentOptions={paymentOptions}
                selectableLicenses={selectableLicenses}
                paymentIcons={paymentIcons}
              />
            </Grid>
          </StyledFormGrid>
        </CardContent>
      </Card>
      <StyledFormDivider my={6} />
      <JournalsContainer
        isAdding={isAdding}
        journals={journals}
        previewJournals={previewJournals}
      />
    </>
  );
};
