import React from 'react';

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Auth components
import SignIn from './pages/auth/SignIn';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';

// Page components
import HomePage from './pages/home/HomePage';
import Statistics from './pages/statistics/Statistics';
import AgreementsView from './pages/agreements/Agreements';
import UserManagement from './pages/userManagement/UserManagement';
import AgreementFormView from './pages/agreements/components/agreement/AgreementForm.container';
import InteralSearch from './pages/internalSearch/InternalSearch';
import Branding from './pages/Branding/Branding';
import Notifications from './pages/notifications/Notifications';
import SuperAdmin from './pages/superAdmin/SuperAdmin';

const routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <HomePage />
          </AuthGuard>
        ),
      },
      {
        path: 'statistics',
        element: (
          <AuthGuard>
            <Statistics />
          </AuthGuard>
        ),
      },
      {
        path: 'internal-search',
        element: (
          <AuthGuard>
            <InteralSearch />
          </AuthGuard>
        ),
      },

      {
        path: 'agreements',
        element: (
          <AuthGuard>
            <AgreementsView />
          </AuthGuard>
        ),
      },
      {
        path: 'agreements/:agreementId',
        element: <AgreementFormView />,
      },
      {
        path: 'agreements/add',
        element: (
          <AuthGuard>
            <AgreementFormView />
          </AuthGuard>
        ),
      },
      {
        path: 'user-management',
        element: (
          <AuthGuard>
            <UserManagement />
          </AuthGuard>
        ),
      },
      {
        path: 'branding',
        element: (
          <AuthGuard>
            <Branding />
          </AuthGuard>
        ),
      },
      {
        path: 'notifications',
        element: (
          <AuthGuard>
            <Notifications />
          </AuthGuard>
        ),
      },
      {
        path: 'superadmin',
        element: (
          <AuthGuard adminOnly>
            <SuperAdmin />
          </AuthGuard>
        ),
      },
    ],
  },

  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
    ],
  },

  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
