import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import SaveIcon from '@mui/icons-material/Save';
import { useUniversities } from '../../../hooks/api/useUniversities';
import { ApiValidationError, isApiError } from '../../../types/Error';

export function UniversityManagement() {
  const { impersonationLock } = useAuthContext();
  const [errors, setErrors] = useState<ApiValidationError | null>(null);
  const { university, updateUniversity, isUpdatingUniversity } =
    useUniversities();
  const [contractExpirationDate, setContractExpirationDate] =
    useState<Dayjs | null>(null);
  const changesMade = useMemo(() => {
    return (
      university?.contract_expiration_date !==
      contractExpirationDate?.toISOString()
    );
  }, [university, contractExpirationDate]);

  useEffect(() => {
    console.log('university', university);
    setContractExpirationDate(
      university?.contract_expiration_date
        ? dayjs(university.contract_expiration_date)
        : null
    );
  }, [university]);

  const handleSave = async () => {
    setErrors(null);
    try {
      console.log(contractExpirationDate);
      await updateUniversity({
        _id: university?._id || '',
        data: {
          contract_expiration_date:
            contractExpirationDate
              ?.hour(12)
              .minute(0)
              .second(0)
              .toISOString() || null,
        },
      });
    } catch (error: unknown) {
      if (isApiError(error)) {
        setErrors(error);
      } else {
        setErrors({
          contract_expiration_date: ['An unknown error occurred'],
        });
      }
    }
  };
  const contractExpirationDateError =
    errors !== null &&
    errors.contract_expiration_date &&
    errors.contract_expiration_date.length > 0;
  return (
    <Box
      display="flex"
      flexDirection="column"
      border="1px dotted gray"
      padding="10px"
      borderRadius="5px"
    >
      <FormControl sx={{ width: '300px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <label
            htmlFor="start_date"
            style={{ color: '#575959', fontSize: '12px' }}
          >
            Contract Expiration Date *
          </label>
          <DatePicker
            slotProps={{ actionBar: { actions: ['clear'] } }}
            format="YYYY-MM-DD"
            disabled={false}
            value={contractExpirationDate}
            onChange={(date) => {
              if (date && date.isValid()) {
                setContractExpirationDate(date);
              } else {
                setContractExpirationDate(null);
              }
            }}
          />
          {contractExpirationDateError && (
            <FormHelperText error>
              Contract Expiration date {errors.contract_expiration_date[0]}
            </FormHelperText>
          )}
        </LocalizationProvider>
      </FormControl>
      <Button
        disabled={!changesMade || impersonationLock}
        sx={{ marginTop: '10px', width: 'fit-content' }}
        style={
          !changesMade
            ? { backgroundColor: 'lightGrey', cursor: 'default' }
            : {}
        }
        type="submit"
        variant="contained"
        endIcon={
          isUpdatingUniversity ? <CircularProgress size={20} /> : <SaveIcon />
        }
        onClick={handleSave}
      >
        {isUpdatingUniversity ? 'Saving...' : 'Save'}
      </Button>
    </Box>
  );
}
