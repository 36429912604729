import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../hooks/api/useNotifications';
import { NotificationStatus } from '../../../types/Notifications.types';
import {
  MarkAsReadButton as UpdateStatusButton,
  NotificationItem,
} from '../../../components/navbar/NotificationsBadge';
import { Notification } from '../../../types/Notifications.types';
import dayjs from 'dayjs';
import { TabType } from '../Notifications';

interface NotificationsTabProps {
  tab: TabType;
}

const NotificationsTab = ({ tab }: NotificationsTabProps) => {
  const { t } = useTranslation();

  const status: NotificationStatus | undefined =
    tab === 'all' ? undefined : tab;

  const { notifications, updateNotification } = useNotifications(status);

  const markAsRead = async (id: string) => {
    await updateNotification({ notificationId: id, newStatus: 'read' });
  };

  const markAsUnread = async (id: string) => {
    await updateNotification({ notificationId: id, newStatus: 'unread' });
  };

  return (
    <Box>
      <Typography variant="subtitle2" mb={10}>
        {t(`notifications.${tab}`)}
      </Typography>

      {!notifications || notifications.length === 0 ? (
        <>
          <Divider sx={{ maxWidth: 800, width: '100%' }} />
          <Typography sx={{ paddingTop: 8 }}>
            {t('notifications.emptyState')}
          </Typography>
        </>
      ) : (
        notifications.map((notification: Notification) => (
          <NotificationItem
            key={notification._id}
            tabIndex={0}
            sx={{ maxWidth: 800, width: '100%' }}
          >
            <Box>
              <Typography variant="body2">
                {notification.notification_type}
              </Typography>
              {notification.updated_by ? (
                <Typography variant="caption" color="textSecondary">
                  {t('notifications.lastUpdatedByOn', {
                    email: notification.updated_by,
                    date: dayjs(notification.updated_at).format(
                      'DD-MM-YYYY HH:mm'
                    ),
                  })}
                </Typography>
              ) : (
                <Typography variant="caption" color="textSecondary">
                  {t('notifications.created', {
                    date: dayjs(notification.created_at).format(
                      'DD-MM-YYYY HH:mm'
                    ),
                  })}
                </Typography>
              )}
            </Box>
            {notification.status === 'unread' ? (
              <UpdateStatusButton
                variant="contained"
                color="primary"
                size="medium"
                tabIndex={0}
                onClick={() => markAsRead(notification._id)}
              >
                {t('notifications.markAsRead')}
              </UpdateStatusButton>
            ) : (
              <UpdateStatusButton
                variant="outlined"
                color="primary"
                size="medium"
                tabIndex={0}
                onClick={() => markAsUnread(notification._id)}
              >
                {t('notifications.markAsUnread')}
              </UpdateStatusButton>
            )}
          </NotificationItem>
        ))
      )}
    </Box>
  );
};

export default NotificationsTab;
