import general from './general.json';
import auth from './auth.json';
import home from './home.json';
import publicSearch from './publicSearch.json';
import agreements from './agreements.json';
import userManagement from './userManagement.json';
import statistics from './statistics.json';
import navbar from './navbar.json';
import notifications from './notifications.json';

export const en = {
  translation: {
    general,
    auth,
    home,
    publicSearch,
    agreements,
    userManagement,
    statistics,
    navbar,
    notifications,
  },
};
