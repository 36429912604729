import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';

export const useSearch = () => {
  const { makeApiRequest } = useApi();
  const { account } = useAuthContext();

  const searchJournals = useCallback(
    async (
      searchQuery: string,
      licenses: string[],
      publishingModels: string[],
      payments: string[],
      imprints: string[]
    ) => {
      let params = `/search?term=${searchQuery}&account=${account}`;

      if (searchQuery === '') {
        return { data: [], licenses, publishingModels, payments, imprints };
      }

      if (licenses.length > 0) {
        params += `&licenses[]=${encodeURI(licenses.join('&licenses[]='))}`;
      }
      if (publishingModels.length > 0) {
        params += `&publishing_model[]=${encodeURI(
          publishingModels.join('&publishing_model[]=')
        )}`;
      }
      if (payments.length > 0) {
        params += `&payment[]=${encodeURI(payments.join('&payment[]='))}`;
      }
      if (imprints.length > 0) {
        params += `&imprint[]=${encodeURI(imprints.join('&imprint[]='))}`;
      }

      const response = await makeApiRequest(params, 'GET');
      let responseLicenses: Set<string> = new Set(response?.filters?.licenses);
      let responsePublishingModels: Set<string> = new Set(
        response?.filters?.publishing_models
      );
      let responsePayments: Set<string> = new Set(response?.filters?.payments);
      let responseImprints: Set<string> = new Set(response?.filters?.imprints);

      let sortSet = (set: Set<string>) => {
        return Array.from(set).sort((a, b) => {
          return a.localeCompare(b);
        });
      };

      return {
        licenses: sortSet(responseLicenses),
        publishingModels: sortSet(responsePublishingModels),
        payments: sortSet(responsePayments),
        imprints: sortSet(responseImprints),
        data: response,
      };
    },
    [makeApiRequest, account]
  );

  return {
    searchJournals,
  };
};
