import { useMutation } from '@tanstack/react-query';
import { useApi } from './useApi';

export const useLoginEvent = () => {
  const { makeApiRequest } = useApi();

  return useMutation({
    mutationFn: () => makeApiRequest('/auth/login-event', 'POST'),
  });
};
