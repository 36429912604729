import { useRef, useState } from 'react';
import {
  IconButton,
  Button,
  Popover,
  Typography,
  Box,
  styled,
  Badge,
} from '@mui/material';
import { NotificationsNone as NotificationsIcon } from '@mui/icons-material';
import { useNotifications } from '../../hooks/api/useNotifications';
import { Notification } from '../../types/Notifications.types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const NotificationButton = styled(IconButton)`
  &:focus {
    outline: 2px solid #177d8e;
  }
`;

export const MarkAsReadButton = styled(Button)`  
  min-width: auto;  
  white-space: nowrap;
  &:focus {
    outline: 2px solid #177d8e;`;

export const NotificationItem = styled(Box)`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #f3f6f9;
  }

  &:focus {
    outline: 2px solid #177d8e;
    outline-offset: -2px;
  }

  &:nth-of-type(n + 2) {
    border-top: 1px solid #ddd;
  }
`;

export const NotificationsBadge = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { notifications, updateNotification } = useNotifications('unread');

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const togglePopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsRead = async (id: string) => {
    await updateNotification({ notificationId: id, newStatus: 'read' });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  return (
    <>
      <NotificationButton
        ref={buttonRef}
        color="secondary"
        onClick={togglePopup}
      >
        <Badge
          badgeContent={
            notifications?.length > 99 ? '99+' : notifications?.length
          }
          color="error"
          overlap="circular"
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '0.65rem',
              minWidth: '16px',
              height: '16px',
              padding: '0 5px',
              borderRadius: '8px',
            },
          }}
        >
          <NotificationsIcon />
        </Badge>
      </NotificationButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            width: 570,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Box p={16}>
          <Typography variant="h4" fontWeight="bold">
            {t('notifications.title')}
          </Typography>

          {!notifications || notifications.length === 0 ? (
            <Typography color="textSecondary" sx={{ mt: 6 }}>
              {t('notifications.emptyState')}
            </Typography>
          ) : (
            <Box
              sx={{
                mt: 6,
                overflowY: 'auto',
                maxHeight: 400,
                borderTop: '1px solid #ddd',
              }}
            >
              {notifications.map((notification: Notification) => (
                <NotificationItem key={notification._id} tabIndex={0}>
                  <Box>
                    <Typography variant="body2">
                      {notification.notification_type}
                    </Typography>
                    {notification.updated_by !== undefined ? (
                      <Typography variant="caption" color="textSecondary">
                        {t('notifications.lastUpdatedByOn', {
                          email: notification.updated_by,
                          date: dayjs(notification.updated_at).format(
                            'DD-MM-YYYY HH:mm'
                          ),
                        })}
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="textSecondary">
                        {t('notifications.created', {
                          date: dayjs(notification.created_at).format(
                            'DD-MM-YYYY HH:mm'
                          ),
                        })}
                      </Typography>
                    )}
                  </Box>
                  <MarkAsReadButton
                    variant="contained"
                    color="primary"
                    size="small"
                    tabIndex={0}
                    onClick={() => markAsRead(notification._id)}
                  >
                    {t('notifications.markAsRead')}
                  </MarkAsReadButton>
                </NotificationItem>
              ))}
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};
