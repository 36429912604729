import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { queryClient } from './react-query';

export const useLicenses = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: licenses } = useQuery({
    queryKey: ['licenses', account],
    queryFn: () => makeApiRequest('/licenses', 'GET'),
    enabled: !!account,
  });

  const { mutateAsync: createLicense, isPending: isCreatingLicense } =
    useMutation({
      mutationFn: ({
        id,
        url,
        description,
        icon,
      }: {
        id: string;
        url?: string;
        description?: string;
        icon?: string;
      }) =>
        makeApiRequest('/licenses', 'POST', {
          name: id,
          url,
          description,
          icon,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['licenses', account] });
      },
    });

  return {
    licenses,
    createLicense,
    isCreatingLicense,
  };
};
