import { Journals } from './Journals.types';

export type Agreements = {
  description: string;
  _id?: string;
  name?: string;
  publisher?: string;
  payment_url?: string;
  journals?: Journals[] | undefined;
  start_date?: string;
  end_date?: string;
  status?: AgreementIsActive | undefined;
  journals_count?: number;
  licenses?: string[];
  account?: string;
  notes?: string;
  payment?: string;
  extra_info?: string;
  updated_at?: string;
  updated_by?: string;
  disabled?: boolean;
};

export enum AgreementIsActive {
  pending = 'pending',
  expired = 'expired',
  active = 'active',
  inactive = 'inactive',
}
