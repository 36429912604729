import { useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';

type journalRequestBody = {
  name: string;
  imprint?: string;
  publishing_model: string;
  issn_e?: string;
  issn_p?: string;
  payment?: string;
  subjects?: string;
  licenses: string[];
  extra_info?: string;
  url?: string;
  disabled?: boolean;
};

export const useAgreements = () => {
  const { makeApiRequest } = useApi();
  const { account } = useAuthContext();

  const fetchAccounts = useCallback(async () => {
    return (await makeApiRequest)('/accounts', 'GET');
  }, [makeApiRequest]);

  const fetchAgreementById = useCallback(
    async (id: string) => {
      return (await makeApiRequest)(`/agreements/${id}`, 'GET');
    },
    [makeApiRequest]
  );

  const fetchAgreements = useCallback(async () => {
    return (await makeApiRequest)('/agreements', 'GET');
  }, [makeApiRequest]);

  const fetchLicenses = useCallback(async () => {
    return (await makeApiRequest)('/settings/licenses', 'GET');
  }, [makeApiRequest]);

  const fetchPayments = useCallback(async () => {
    const payments = await makeApiRequest('/settings/payments', 'GET');
    return payments.sort();
  }, [makeApiRequest]);

  const fetchPublishers = useCallback(async () => {
    return (await makeApiRequest)('/settings/publishers', 'GET');
  }, [makeApiRequest]);

  const addAgreement = async (data: FormData) => {
    return await makeApiRequest('/agreements', 'POST', data);
  };

  const editAgreement = async (data: FormData, id: string) => {
    return await makeApiRequest(`/agreements/${id}`, 'PUT', data);
  };

  const deleteAgreementById = async (id: string) => {
    await makeApiRequest(`/agreements/${id}`, 'DELETE');
  };

  const previewAgreement = useCallback(
    async (data: FormData) => {
      return await makeApiRequest('/agreements/preview', 'POST', data);
    },
    [makeApiRequest]
  );

  const addJournal = async (data: journalRequestBody, agreementId: string) => {
    return await makeApiRequest(
      `/agreements/${agreementId}/journals`,
      'POST',
      data
    );
  };

  const editJournal = async (
    data: journalRequestBody,
    agreementId: string,
    journalId: string
  ) => {
    return await makeApiRequest(
      `/agreements/${agreementId}/journals/${journalId}`,
      'PUT',
      data
    );
  };

  const deleteJournal = async (agreementId: string, journalId: string) => {
    return await makeApiRequest(
      `/agreements/${agreementId}/journals/${journalId}`,
      'DELETE'
    );
  };

  const searchJournals = useCallback(
    async (
      searchQuery: string,
      licenses: string[],
      publishingModels: string[]
    ) => {
      let params = `/search?term=${searchQuery}&account=${account}`;

      if (searchQuery === '') {
        return [];
      }

      if (licenses.length === 0 && publishingModels.length === 0) {
        return (await makeApiRequest)(params, 'GET');
      }
      if (licenses.length > 0) {
        params += `&licenses[]=${encodeURI(licenses.join('&licenses[]='))}`;
      }
      if (publishingModels.length > 0) {
        params += `&publishing_model[]=${encodeURI(
          publishingModels.join('&publishing_model[]=')
        )}`;
      }
      return (await makeApiRequest)(params, 'GET');
    },
    [makeApiRequest, account]
  );

  return {
    fetchAccounts,
    fetchAgreementById,
    fetchAgreements,
    fetchLicenses,
    fetchPayments,
    fetchPublishers,
    addAgreement,
    deleteAgreementById,
    editAgreement,
    previewAgreement,
    addJournal,
    editJournal,
    deleteJournal,
    searchJournals,
  };
};
