import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { queryClient } from './react-query';
import { UniversityUpdateRequest } from '../../types/Universities.types';
import { University } from '../../types/Universities.types';
export const useUniversities = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();

  const { data: university } = useQuery({
    queryKey: ['universities', account],
    queryFn: () =>
      makeApiRequest(`/universities/${account}`, 'GET') as Promise<University>,
    enabled: !!account,
  });

  const { mutateAsync: updateUniversity, isPending: isUpdatingUniversity } =
    useMutation({
      mutationFn: (params: { _id: string; data: UniversityUpdateRequest }) =>
        makeApiRequest(`/universities/${params._id}`, 'PUT', params.data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['universities', account] });
      },
    });

  return {
    university,
    updateUniversity,
    isUpdatingUniversity,
  };
};
