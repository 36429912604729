import { Box, Button, Table, TableCell, TableRow } from '@mui/material';
import styled from '@emotion/styled';
import { AgreementIsActive } from '../../../types/Agreements.types';

/** PRESETS FOR AGREEMENT TABLE */

const statusColors = {
  [AgreementIsActive.active]: '#E6F4E6',
  [AgreementIsActive.pending]: '#A9B5BB',
  [AgreementIsActive.expired]: '#F7DFDF',
  [AgreementIsActive.inactive]: '#D3D3D3',
};

export const StyledTableBox = styled(Box)<{
  status: AgreementIsActive | undefined;
}>`
  && {
    color: #0b313f;
    font-weight: 600;
    height: 20px;
    text-align: center;
    text-transform: capitalize;
    width: 60%;
    margin: auto;
    border-radius: 0.5rem;
    background-color: ${(props) => props.status && statusColors[props.status]}
`;

export const StyledTableButton = styled(Button)`
  && {
    padding: 8px;
    text-transform: none;
    font-weight: 500;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
    &:focus {
      outline: 2px solid #177d8e;
    }
  }
`;

export const StyledTableCell = styled(TableCell)<{
  heavy: boolean;
  topBorder: boolean;
}>`
  && {
    font-size: 14px;
    font-weight: ${(props) => (props.heavy ? '600' : '400')};
    border: none;
    border-top: ${(props) => (props.topBorder ? '1px solid #f5f5f5' : 'none')};
  }
`;

export const StyledTableDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledTableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledTableRow = styled(TableRow)`
  & th,
  td {
    width: 16.66%;
  }
`;

export const StyledTable = styled(Table)`
  & tbody tr:hover {
    background-color: #f3f6f9;
    color: #0b313f;
    cursor: pointer;
  }
`;
