import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../Loader';
import { useAuthContext } from '../../contexts/AuthContext';

interface AuthGuardType {
  children: React.ReactNode;
  adminOnly?: boolean;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, adminOnly = false }: AuthGuardType) {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const { loggedInAccount } = useAuthContext();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (adminOnly && loggedInAccount !== 'admin') {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
