import {
  Box,
  CardContent,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { NotificationsTabSelect } from './components/NotificationsTabSelect';
import { useState } from 'react';
import NotificationsTab from './components/NotificationsTab';
import styled from '@emotion/styled';

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

export type TabType = 'unread' | 'read' | 'all';

const Notifications = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<TabType>('unread');

  const handleTabSelect = (tab: TabType) => {
    setTab(tab);
  };
  return (
    <Card>
      <Grid container spacing={2} p={16}>
        <Grid item xs={9}>
          <Typography variant="h1" sx={{ fontSize: 20 }}>
            {t('home.notificationsTitle')}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            {t('home.notificationsSubtext')}
          </Typography>
        </Grid>
        <Divider />
        <Box sx={{ width: '100%' }}>
          <NotificationsTabSelect onTabSelect={handleTabSelect} />
          <Card>
            <CardContent>
              <NotificationsTab tab={tab} />
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Card>
  );
};

export default Notifications;
